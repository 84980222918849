import { defaultOptions } from "./helper";
import axios from "axios";

export function getReportList(token) {
  return axios.get(`/reports`, defaultOptions(token));
}

export function getReport(token, reportId, args) {
  return axios.post(
    `/reports`,
    {
      reportId,
      args,
    },
    defaultOptions(token)
  );
}
