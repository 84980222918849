import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic";
import { useSelector, shallowEqual } from "react-redux";

import { retry } from "../components/retry";

import {
  getProjects,
  getProjectTypes,
  getPlanningAuthorities,
} from "../../store/ducks/projects.duck";
import {
  getUsers,
  getBudgetRoles,
  getUserRoles,
  getMe,
  getRateCards,
} from "../../store/ducks/app.duck";
import { getClients } from "../../store/ducks/clients.duck";
import { getInternalCodes } from "../../store/ducks/timesheet.duck";
import { usePageTracking } from "../../hooks/usePageTracking";

const windowReload = () => window.location.reload();

const ProjectsList = lazy(() =>
  retry(() => import("../projects/NewProjectList"), windowReload)
);

const ProjectDetails = lazy(() =>
  retry(() => import("../projects/ProjectDetails"), windowReload)
);
const ProjectEdit = lazy(() =>
  retry(() => import("../projects/ProjectEdit"), windowReload)
);

const ClientsList = lazy(() =>
  retry(() => import("../clients/ClientsList"), windowReload)
);
const ClientDetails = lazy(() =>
  retry(() => import("../clients/ClientDetails"), windowReload)
);
const ClientEditPage = lazy(() =>
  retry(() => import("../clients/ClientEditPage"), windowReload)
);
const ClientNewPage = lazy(() =>
  retry(() => import("../clients/ClientNewPage"), windowReload)
);
const ReportsSelectPage = lazy(() =>
  retry(() => import("../reports/ReportsSelectPage"), windowReload)
);
const ReportsPage = lazy(() =>
  retry(() => import("../reports/ReportsPage"), windowReload)
);
const UsersPage = lazy(() =>
  retry(() => import("../users/UsersPage"), windowReload)
);
const HolidayPage = lazy(() =>
  retry(() => import("../holiday/HolidayPage"), windowReload)
);
const TimeSheet = lazy(() =>
  retry(() => import("../time/TimeSheet"), windowReload)
);

export default function HomePage() {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );
  usePageTracking({ user });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMe());
    dispatch(getUsers());
    dispatch(getProjects());
    dispatch(getProjectTypes());
    dispatch(getPlanningAuthorities());
    dispatch(getClients());
    dispatch(getBudgetRoles());
    dispatch(getUserRoles());
    dispatch(getInternalCodes());
    dispatch(getRateCards());
  }, [dispatch]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/projects" />

        <Route path="/time" exact component={TimeSheet} />
        <Route path="/time/user/:impersonate" exact component={TimeSheet} />
        <Route path="/time/:date" exact component={TimeSheet} />
        <Route
          path="/time/:date/user/:impersonate"
          exact
          component={TimeSheet}
        />

        <Route path="/clients" exact component={ClientsList} />
        <Route path="/clients/new" exact component={ClientNewPage} />
        <Route path="/clients/:id" exact component={ClientDetails} />
        <Route path="/clients/:id/edit" exact component={ClientEditPage} />

        <Route path="/projects" exact component={ProjectsList} />
        <Route path="/projects/:id" exact component={ProjectDetails} />
        <Route path="/projects/:id/edit" exact component={ProjectEdit} />
        <Route path="/projects/:id/edit/:tab" exact component={ProjectEdit} />

        <Route path="/reports" exact component={ReportsSelectPage} />
        <Route path="/reports/:id" exact component={ReportsPage} />
        <Route path="/users" exact component={UsersPage} />
        <Route path="/holiday" exact component={HolidayPage} />
        <Route path="/holiday/:tab" exact component={HolidayPage} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
