import axios from "axios";
import { defaultOptions } from "./helper";

export function getTimesheet(token, date) {
  return axios.get(`/time?date=${date.toISOString()}`, defaultOptions(token));
}

export function getTimesheetForUser(token, date, user) {
  return axios.get(
    `/time?date=${date.toISOString()}&user=${user}`,
    defaultOptions(token)
  );
}

export function getTimeForProject(token, project) {
  return axios.get(`/time/project/${project}`, defaultOptions(token));
}

export function postTimeEntry(token, data) {
  return axios.post("/time/entry", data, defaultOptions(token));
}

export function putTimeLogged(token, data) {
  return axios.put("/time/entry", data, defaultOptions(token));
}

export function moveTimeEntry(token, data) {
  return axios.patch("/time/entry", data, defaultOptions(token));
}

export function moveTimeEntryDay(token, data) {
  return axios.patch("/time/entry/day", data, defaultOptions(token));
}

export function deleteTimeEntry(token, data) {
  return axios.delete("/time/entry", { data, ...defaultOptions(token) });
}

export function postSubmitTimesheet(token, data) {
  return axios.post("/time/submit", data, defaultOptions(token));
}

export function postUnsubmitTimesheet(token, data) {
  return axios.post("/time/unsubmit", data, defaultOptions(token));
}

export function getInternalCodes(token) {
  return axios.get(`/time/internalCodes`, defaultOptions(token));
}
