import React, { useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";

import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { logout } from "../../store/ducks/auth.duck";

export default function UserProfile() {
  const dispatch = useDispatch();
  const { user, me } = useSelector(
    (state) => ({
      user: state.auth.user,
      me: state.app.me?.portal,
    }),
    shallowEqual
  );
  const hour = useMemo(() => new Date().getHours(), []);
  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          <span className="kt-header__topbar-username kt-hidden-mobile">
            {hour < 12 ? "Morning, " : hour < 18 ? "Afternoon, " : "Evening, "}{" "}
            {me?.displayName || user.name}
          </span>

          <img alt={user.name} src={user.picture} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="p-2">
          <div className="text-muted">
            Default role: <code>{me?.role.name}</code>
          </div>
          <div className="text-muted">
            Portal ID: <code>{me?.id}</code>
          </div>
        </div>
        <div className="kt-notification">
          {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Messages
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </a> */}
          <div className="kt-notification__custom">
            <button
              onClick={() =>
                dispatch(
                  logout({
                    returnTo: "http://localhost:3000/",
                  })
                )
              }
              className="btn btn-label-brand btn-sm btn-bold"
            >
              Sign Out
            </button>
            {/* <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
