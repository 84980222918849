import { createReducer } from "redux-act";
import { takeLatest, call, put, select } from "redux-saga/effects";
import createAuth0Client from "@auth0/auth0-spa-js";
import { createAction } from "redux-act";
import * as Sentry from "@sentry/browser";
import { identifyUser } from "../../hooks/identifyUser";

export const init = createAction("auth/INIT");
export const login = createAction("auth/LOGIN");
export const logout = createAction("auth/LOGOUT");

const setClient = createAction("auth/SET_CLIENT");
const setAuthenticated = createAction("auth/SET_AUTHENTICATED");
const setUser = createAction("auth/SET_USER");
const setLoading = createAction("auth/SET_LOADING");

const initialState = {
  initialized: false,
  isAuthenticated: false,
  user: null,
  auth0Client: null,
  loading: true,
  redirectCallback: (...args) => {
    console.log("redirectCallback", ...args);
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export const reducer = createReducer(
  {
    [init]: (state) => state,
    [login]: (state) => state,
    [logout]: (state) => state,
    [setClient]: (state, client) => {
      return { ...state, initialized: true, auth0Client: client };
    },
    [setAuthenticated]: (state, isAuthenticated) => {
      return { ...state, isAuthenticated };
    },
    [setUser]: (state, user) => {
      return { ...state, user };
    },
    [setLoading]: (state, loading) => {
      return { ...state, loading };
    },
  },
  initialState
);

export function* saga() {
  yield takeLatest(init, function* authInitSaga(...args) {
    const onRedirectCallback = yield select(
      (state) => state.auth.redirectCallback
    );

    const client = yield call(createAuth0Client, {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      client_id: process.env.REACT_APP_AUTH_CLIENTID,
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      redirect_uri: window.location.origin,
      useRefreshTokens: true,
      useCookiesForTransactions: true,
    });
    yield put(setClient(client));
    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      yield call({
        context: client,
        fn: client.handleRedirectCallback,
      });
      onRedirectCallback();
    }
    const isAuthenticated = yield call({
      context: client,
      fn: client.isAuthenticated,
    });

    yield put(setAuthenticated(isAuthenticated));
    if (isAuthenticated) {
      const user = yield call({ context: client, fn: client.getUser });
      yield call(Sentry.setUser, user);
      yield call(identifyUser, user);
      yield put(setUser(user));
    }
    yield put(setLoading(false));
  });

  yield takeLatest(login, function* loginSaga(...args) {
    const client = yield select((state) => state.auth.auth0Client);
    yield call(
      { context: client, fn: client.loginWithRedirect },
      {
        redirect_uri: window.location.origin,
      }
    );
  });

  yield takeLatest(logout, function* logoutSaga(...args) {
    const client = yield select((state) => state.auth.auth0Client);
    yield call({ context: client, fn: client.logout }, args.payload);
  });
}
