import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jitsuAnalytics } from "@jitsu/js";

export function usePageTracking(props) {
  const analytics = jitsuAnalytics({
    host: "https://clvmgwbsa00003b6tzgc1lib2.d.jitsu.com",
  });
  const location = useLocation();

  useEffect(() => {
    analytics.page(location.pathname, { url: window.location.href });
  }, [props, location, analytics]);
}
