export default {
  header: {
    self: {},
    items: [
      {
        title: "Clients",
        root: true,
        alignment: "left",
        page: "clients",
      },
      {
        title: "Projects",
        root: true,
        alignment: "left",
        page: "projects",
        //submenu: [{ title: 'Project list', page: 'projects'  }],
      },

      {
        title: "Time",
        root: true,
        alignment: "left",
        permission: "read:time",
        page: "time",
      },
      {
        title: "Holiday",
        root: true,
        permission: "read:holiday",
        alignment: "left",
        page: "holiday",
      },

      {
        title: "Reports",
        root: true,
        permission: "read:reports",
        alignment: "left",
        page: "reports",
      },
      {
        title: "Users",
        root: true,
        permission: "read:users",
        alignment: "left",
        page: "users",
      },
      //   {
      //     title: "Admin",
      //     root: true,
      //     alignment: "left",
      //     toggle: "click",
      //     submenu: [
      //       {
      //         title: "Manage users",
      //       },
      //     ],
      //   },
    ],
  },
};
