import { baseURL } from "./helper";
import axios from "axios";

const config = (token) => ({
  baseURL,
  headers: { Authorization: `Bearer ${token}` },
});

export function getUsers(token) {
  return axios.get(`/users?active=true`, config(token));
}

export function getArchivedUsers(token) {
  return axios.get(`/users?active=false`, config(token));
}

export function getUserRoles(token) {
  return axios.get(`/users/role`, config(token));
}

export function getBudgetRoles(token) {
  return axios.get(`/users/budget/role`, config(token));
}

export function getMe(token) {
  return axios.get(`/users/me`, config(token));
}

export function getRateCards(token) {
  return axios.get(`/accounts/ratecards`, config(token));
}
