import { createSagaActions } from "./utils";
import { createReducer, createAction } from "redux-act";
import { push } from "connected-react-router";
import { takeLatest, put } from "redux-saga/effects";
import {
  getAll,
  postClientDetails,
  putClientDetails,
  deleteClientDetails
} from "../../crud/client.crud";
import orderBy from "lodash/orderBy";
import { GetAccessToken } from "./accessToken";

export const createClient = createSagaActions("clients/CREATE_CLIENT");
export const getClients = createSagaActions("clients/GET_CLIENTS");
export const sortClients = createAction("clients/SORT_CLIENTS");
export const updateClient = createSagaActions("clients/UPDATE_CLIENT");
export const deleteClient = createSagaActions("clients/DELETE_CLIENT");

const initialState = {
  items: [],
  loading: false,
  sortKey: "name",
  sortOrder: "ASC"
};

const sort = (data, by, direction) => orderBy(data, by, direction);

export const reducer = createReducer(
  {
    [getClients]: state => ({ ...state, loading: true }),
    [getClients.done]: (state, data) => {
      return {
        ...state,
        items: sort(data, ["lastName", "firstName"], ["asc", "asc"]),
        loading: false
      };
    },
    [getClients.fail]: state => {
      return { ...state, loading: false };
    },
    [updateClient]: state => ({ ...state, loading: true }),
    [updateClient.done]: (state, { id, data }) => {
      const { items } = state;
      const clientIndex = items.findIndex(client => client.id === id);
      const client = items[clientIndex];
      const updatedClient = { ...client, ...data };
      items[clientIndex] = updatedClient;
      return { ...state, items, loading: false };
    },
    [updateClient.fail]: state => ({ ...state, loading: false }),

    [createClient]: state => ({ ...state, loading: true }),
    [createClient.done]: state => ({ ...state, loading: false }),
    [createClient.fail]: state => ({ ...state, loading: false }),

    [deleteClient]: state => ({ ...state, loading: true }),
    [deleteClient.done]: state => ({
      ...state,
      loading: false
    }),
    [deleteClient.fail]: state => ({ ...state, loading: false }),

    [sortClients]: (state, { key, sortOrder }) => {
      const items = state.items;
      const by = (key === "name" && ["lastName", "firstName"]) ||
        (key === "company" && ["company.name"]) || [key];
      const direction = by.map(_ => sortOrder.toLowerCase());
      return {
        ...state,
        items: sort(items, by, direction),
        sortKey: key,
        sortOrder
      };
    }
  },
  initialState
);

export function* saga() {
  yield takeLatest(getClients, function* getClientsSaga() {
    const token = yield* GetAccessToken();
    const result = yield getAll(token);
    yield put(getClients.done(result.data));
  });

  yield takeLatest(updateClient, function* updateClientDetailsSaga({
    payload: { id, data }
  }) {
    const token = yield* GetAccessToken();
    const result = yield putClientDetails(token, id, data);
    yield put(updateClient.done({ id, data: result.data }));
    yield put(push(`/clients/${id}`));
  });

  yield takeLatest(createClient, function* createClientDetailsSaga({
    payload: { data }
  }) {
    const token = yield* GetAccessToken();
    const {
      data: { id }
    } = yield postClientDetails(token, data);
    yield put(createClient.done());
    yield put(getClients());
    yield put(push(`/clients/${id}`));
  });

  yield takeLatest(deleteClient, function* deleteClientDetailsSaga({
    payload: { id }
  }) {
    const token = yield* GetAccessToken();
    const result = yield deleteClientDetails(token, id);
    yield put(deleteClient.done(result.data));
    yield put(getClients());
    yield put(push("/clients"));
  });
}
