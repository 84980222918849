import { createSagaActions } from "./utils";
import { createReducer } from "redux-act";
import { takeLatest, put } from "redux-saga/effects";
import {
  getReportList as getReportListFromApi,
  getReport as getReportFromApi,
} from "../../crud/reports.crud";
import { GetAccessToken } from "./accessToken";

export const getReportList = createSagaActions("reports/GET_LIST");
export const getReport = createSagaActions("reports/GET_REPORT_DATA");

const initialState = {
  reports: [],
  resultData: null,
  listLoading: false,
  reportLoading: false,
};

export const reducer = createReducer(
  {
    [getReportList]: (state) => ({ ...state, listLoading: true }),
    [getReportList.done]: (state, data) => {
      return {
        ...state,
        reports: data,
        listLoading: false,
      };
    },
    [getReportList.fail]: (state) => {
      return { ...state, listLoading: false };
    },
    [getReport]: (state) => ({ ...state, reportLoading: false }),
    [getReport.done]: (state, data) => {
      return {
        ...state,
        resultData: data,
        reportLoading: false,
      };
    },
    [getReport.fail]: (state) => {
      return { ...state, reportLoading: false };
    },
  },
  initialState
);

export function* saga() {
  yield takeLatest(getReportList, function* getReportListSaga() {
    const token = yield* GetAccessToken();
    const result = yield getReportListFromApi(token);
    yield put(getReportList.done(result.data.reports));
  });

  yield takeLatest(getReport, function* getReportDataSaga({
    payload: { reportId, args },
  }) {
    const token = yield* GetAccessToken();
    const result = yield getReportFromApi(token, reportId, args);
    yield put(getReport.done(result.data));
  });
}
