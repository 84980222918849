import { createSagaActions, GetUserSub } from "./utils";
import { createReducer, createAction } from "redux-act";
import { takeLatest, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import orderBy from "lodash/orderBy";
import {
  create,
  getAll,
  getProjectDetails,
  putProjectDetails,
  putProjectNote,
  getProjectTypes as getProjectTypesFromApi,
  getPlanningAuthorities as getPlanningAuthsFromApi,
  postWorkstage,
  putBudget,
  deleteWorkstage as deleteWorkstageFromApi,
  putWorkstage,
  putWorkstagePercentageComplete,
  putWorkstageOrder,
  putFavProject,
  putUnfavProject,
  putProjectReview,
  addProjectInvoice as addInvoiceApi,
  removeProjectInvoice as removeInvoiceApi,
} from "../../crud/project.crud";
import { getTimeForProject as getTimeForProjectFromApi } from "../../crud/time.crud";

import { GetAccessToken } from "./accessToken";
import { createSelector } from "reselect";

const findProjectIndexById = (id, projects) =>
  projects.findIndex((p) => p.id === id);

export const getProjectTypes = createSagaActions("projects/GET_PROJECT_TYPES");
export const getPlanningAuthorities = createSagaActions(
  "projects/GET_PLANNING_AUTHS"
);

export const createProject = createSagaActions("projects/CREATE_PROJECT");
export const getProjects = createSagaActions("projects/GET_PROJECTS");
export const getProject = createSagaActions("projects/GET_PROJECT");
export const updateProject = createSagaActions("projects/UPDATE_PROJECT");
export const createWorkstage = createSagaActions("project/CREATE_WORKSTAGE");
export const updateBudget = createSagaActions("projects/UPDATE_BUDGET");
export const updateWorkstage = createSagaActions("projects/UPDATE_WORKSTAGE");
export const deleteWorkstage = createSagaActions("projects/DELETE_WORKSTAGE");
export const updateStatus = createSagaActions("projects/UPDATE_STATUS");
export const updateWorkstageOrder = createSagaActions(
  "project/UPDATE_WORKSTAGE_ORDER"
);

export const updateWorkstagePercentage = createSagaActions(
  "projects/UPDATE_WORKSTAGE_PERCENTAGE"
);

export const autoPopulateInvoiceSchedule = createSagaActions(
  "projects/POPULATE_INVOICE_SCHEDULE"
);
export const moveInvoiceSchedule = createSagaActions(
  "projects/MOVE_INVOICE_SCHEDULE"
);
export const addNote = createSagaActions("projects/ADD_PROJECT_NOTES");
export const getInvoiceDisplay = createSagaActions(
  "projects/GET_INVOICE_DISPLAY"
);
export const sortProjects = createAction("projects/SORT_PROJECTS");
export const updateVisibilityFilter = createAction(
  "projects/UPDATE_VISIBILITY"
);

export const getTimeForProject = createSagaActions("projects/GET_TIME");
export const favProject = createSagaActions("projects/FAVOURITE");
export const unfavProject = createSagaActions("projects/UNFAVOURITE");

export const updateProjectReview = createSagaActions("projects/UPDATE_REVIEW");

export const addProjectInvoice = createSagaActions("projects/ADD_INVOICE");
export const removeProjectInvoice = createSagaActions(
  "projects/REMOVE_INVOICE"
);

export const updateWorkstageLock = createSagaActions("projects/UPDATE_LOCK");

const initialState = {
  projects: [],
  loading: false,
  listLoading: false,
  selectedProject: null,
  types: [],
  planningAuthorities: [],
  display: null,
  sortKey: "name",
  sortOrder: "ASC",
  visibilityFilter: "LIVE",
  selectedProjectTime: null,
  projectReviewLoading: false,
};

export const reducer = createReducer(
  {
    [createProject]: (state) => ({ ...state, loading: true }),
    [createProject.done]: (state) => {
      return { ...state, loading: false };
    },
    [createProject.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getProjects]: (state) => ({ ...state, listLoading: true }),
    [getProjects.done]: (state, data) => {
      return { ...state, projects: data, listLoading: false };
    },
    [getProjects.fail]: (state) => {
      return { ...state, listLoading: false };
    },

    [getProject]: (state) => ({
      ...state,
      selectedProject: null,
      loading: true,
    }),
    [getProject.done]: (state, data) => ({
      ...state,
      selectedProject: data,
      loading: false,
    }),
    [getProject.fail]: (state) => ({ ...state, loading: false }),

    [createWorkstage]: (state) => ({ ...state, loading: true }),
    [createWorkstage.done]: (state) => ({
      ...state,
      loading: false,
    }),

    [updateProject]: (state) => ({ ...state, loading: true }),
    [updateProject.done]: (state, data) => ({
      ...state,
      loading: false,
      selectedProject: data,
    }),
    [updateProject.fail]: (state) => ({ ...state, loading: false }),
    [getProjectTypes]: (state) => ({ ...state, loading: true }),
    [getProjectTypes.done]: (state, data) => ({
      ...state,
      loading: false,
      types: data,
    }),

    [addNote]: (state) => ({ ...state, loading: true }),
    [addNote.done]: (state, data) => ({
      ...state,
      selectedProject: data,
      loading: false,
    }),
    [addNote.fail]: (state) => ({ ...state, loading: false }),

    [getProjectTypes.fail]: (state) => ({ ...state, loading: false }),
    [getPlanningAuthorities]: (state) => ({ ...state, loading: true }),
    [getPlanningAuthorities.done]: (state, data) => ({
      ...state,
      loading: false,
      planningAuthorities: data,
    }),
    [getPlanningAuthorities.fail]: (state) => ({
      ...state,
      loading: false,
    }),
    [updateBudget]: (state) => ({ ...state, loading: true }),
    [updateBudget.done]: (state) => ({
      ...state,
      loading: false,
    }),
    [updateBudget.fail]: (state) => ({ ...state, loading: false }),
    [updateWorkstage]: (state) => ({ ...state, loading: true }),
    [updateWorkstage.done]: (state) => ({
      ...state,
      loading: false,
    }),
    [updateWorkstage.fail]: (state) => ({ ...state, loading: false }),
    [deleteWorkstage]: (state) => ({ ...state, loading: true }),
    [deleteWorkstage.done]: (state) => ({
      ...state,
      loading: false,
    }),
    [deleteWorkstage.fail]: (state) => ({ ...state, loading: false }),
    [updateStatus]: (state) => state,
    [updateStatus.done]: (state, { id, status, code }) => {
      const { selectedProject, projects } = state;
      const projectIndex = findProjectIndexById(id, projects);
      const project = projects[projectIndex];
      const updatedProject = { ...project, status, code };
      projects[projectIndex] = updatedProject;
      const updatedSelected = { ...selectedProject, status, code };
      return { ...state, selectedProject: updatedSelected };
    },
    [updateStatus.fail]: (state) => ({ ...state, loading: false }),
    [sortProjects]: (state, { key, sortOrder }) => {
      const isNumeric = key === "code" || key === "percentage";
      const items = state.projects;
      const by = (key === "name" && ["title"]) ||
        (key === "company" && ["client.company.name"]) ||
        (key === "owner" && ["owner.lastName", "owner.firstName"]) ||
        (key === "percentage" && ["percentageComplete"]) || [key];

      const direction = by.map((_) => sortOrder.toLowerCase());
      const sortedProjects = orderBy(
        items,
        isNumeric ? (i) => parseInt(i[by[0]], 10) : by,
        direction
      );
      return {
        ...state,
        sortKey: key,
        sortOrder,
        projects: sortedProjects,
      };
    },
    [updateVisibilityFilter]: (state, filter) => ({
      ...state,
      visibilityFilter: filter,
    }),
    [updateWorkstageOrder]: (state, { workstages }) => {
      const { selectedProject: project } = state;
      const { budget } = project;
      const { workstages: existingWorkstages } = budget;

      const newWorkstages = existingWorkstages.map((w) => {
        console.log(workstages);
        const sortOrder = workstages.find((ww) => ww.id === w.id).order;
        return { ...w, sortOrder };
      });
      const sortedWorkstages = orderBy(newWorkstages, "sortOrder", "asc");
      return {
        ...state,
        selectedProject: {
          ...project,
          budget: { ...budget, workstages: sortedWorkstages },
        },
      };
    },
    [updateWorkstageOrder.done]: (state) => ({ ...state }),
    [updateWorkstageOrder.fail]: (state) => ({ ...state }),

    [updateWorkstagePercentage]: (state) => ({ ...state }),
    [updateWorkstagePercentage.done]: (
      state,
      {
        projectId,
        workstageId,
        projectPercentageComplete,
        workstageFeeByCumulativeHours,
        forecastFeeByCumulativeHours,
      }
    ) => {
      const { selectedProject, projects } = state;
      const projectIndex = findProjectIndexById(projectId, projects);
      const { budget } = selectedProject;

      let { workstages: updatedWorkstages } = budget;

      const workstageIndex = updatedWorkstages.findIndex(
        (w) => w.id === workstageId
      );

      if (workstageIndex >= 0) {
        updatedWorkstages[
          workstageIndex
        ].feeByCumulativeHours = workstageFeeByCumulativeHours;
        updatedWorkstages = orderBy(updatedWorkstages, "sortOrder", "asc");
      }

      const updatedSelected = {
        ...selectedProject,
        budget: {
          ...budget,
          workstages: updatedWorkstages,
          forecastFeeByCumulativeHours,
        },
        percentageComplete: projectPercentageComplete,
      };

      const updatedProjects = [...projects];
      updatedProjects[projectIndex] = updatedSelected;

      return {
        ...state,
        projects: updatedProjects,
        selectedProject: updatedSelected,
      };
    },
    [updateWorkstagePercentage.fail]: (state) => ({ ...state }),
    [getTimeForProject]: (state) => ({ ...state, loading: true }),
    [getTimeForProject.done]: (state, data) => ({
      ...state,
      loading: false,
      selectedProjectTime: data,
    }),
    [getTimeForProject.fail]: (state) => ({ ...state, loading: false }),
    [favProject]: (state) => {
      const { selectedProject: project } = state;
      project.isFavByMe = true;
      return { ...state, selectedProject: project };
    },
    [favProject.done]: (state) => state,
    [favProject.fail]: (state) => state,
    [unfavProject]: (state) => {
      const { selectedProject: project } = state;
      project.isFavByMe = false;
      return { ...state, selectedProject: project };
    },
    [unfavProject.done]: (state) => state,
    [unfavProject.fail]: (state) => state,
    [updateProjectReview]: (state) => ({
      ...state,
      projectReviewLoading: true,
    }),
    [updateProjectReview.done]: (state) => ({
      ...state,
      projectReviewLoading: false,
    }),
    [updateProjectReview.fail]: (state) => ({
      ...state,
      projectReviewLoading: false,
    }),
  },
  initialState
);

export function* saga() {
  yield takeLatest(createProject, function* createProjectSaga({
    payload: data,
  }) {
    const token = yield* GetAccessToken();
    const owner = yield* GetUserSub();
    const updatedData = { ...data, owner };

    const {
      data: { id },
    } = yield call(create, token, updatedData);
    yield put(createProject.done());
    yield put(push(`/projects/${id}/edit`));
    yield put(getProjects());
  });

  yield takeLatest(getProjects, function* getProjectsSaga() {
    const token = yield* GetAccessToken();
    const result = yield getAll(token);
    yield put(getProjects.done(result.data));
  });

  yield takeLatest(getProject, function* getProjectDetailsSaga({
    payload: id,
  }) {
    const token = yield* GetAccessToken();
    const result = yield getProjectDetails(token, id);
    yield put(getProject.done(result.data));
  });

  yield takeLatest(updateProject, function* updateProjectDetailsSaga({
    payload: { id, data },
  }) {
    const token = yield* GetAccessToken();
    const result = yield putProjectDetails(token, id, data);
    yield put(updateProject.done(result.data));
  });

  yield takeLatest(addNote, function* addProjectNotesSaga({
    payload: { projectId, note },
  }) {
    const token = yield* GetAccessToken();
    const result = yield putProjectNote(token, projectId, note);
    yield put(addNote.done(result.data));
  });

  yield takeLatest(getProjectTypes, function* getProjectTypesSaga() {
    const token = yield* GetAccessToken();
    const result = yield getProjectTypesFromApi(token);
    yield put(getProjectTypes.done(result.data));
  });

  yield takeLatest(getPlanningAuthorities, function* getPlanningAuthsSaga() {
    const token = yield* GetAccessToken();
    const result = yield getPlanningAuthsFromApi(token);
    yield put(getPlanningAuthorities.done(result.data));
  });

  yield takeLatest(createWorkstage, function* createWorkstageSaga({
    payload: { data },
  }) {
    const { project } = data;
    const token = yield* GetAccessToken();
    yield call(postWorkstage, token, data);
    yield put(getProject(project));
  });

  yield takeLatest(updateBudget, function* putBudgetSaga({
    payload: { id, data },
  }) {
    const token = yield* GetAccessToken();
    yield call(putBudget, token, id, data);
    yield put(getProject(id));
  });

  yield takeLatest(addProjectInvoice, function* addInvoiceSaga({
    payload: { id, data },
  }) {
    const token = yield* GetAccessToken();
    yield call(addInvoiceApi, token, id, data);
    yield put(getProject(id));
  });

  yield takeLatest(removeProjectInvoice, function* removeInvoiceSaga({
    payload: { id, invoiceId },
  }) {
    const token = yield* GetAccessToken();
    yield call(removeInvoiceApi, token, id, invoiceId);
    yield put(getProject(id));
  });

  yield takeLatest(deleteWorkstage, function* deleteWorkstageSaga({
    payload: { id, projectId },
  }) {
    const token = yield* GetAccessToken();
    yield call(deleteWorkstageFromApi, token, projectId, id);
    yield put(getProject(projectId));
  });

  yield takeLatest(updateWorkstage, function* updateWorkstageSaga({
    payload: { id, projectId, data },
  }) {
    const token = yield* GetAccessToken();
    yield call(putWorkstage, token, id, data);
    yield put(getProject(projectId));
  });
  yield takeLatest(updateWorkstageLock, function* updateWorkstageSaga({
    payload: { id, projectId, data },
  }) {
    const { isLocked, project } = data;
    const token = yield* GetAccessToken();
    yield call(putWorkstage, token, id, { isLocked, project });
    yield put(getProject(projectId));
  });

  yield takeLatest(getTimeForProject, function* getTimeForProjectSaga({
    payload: id,
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(getTimeForProjectFromApi, token, id);

    yield put(getTimeForProject.done(result.data));
  });

  yield takeLatest(updateStatus, function* updateStatusSaga({
    payload: { id, status, code = null },
  }) {
    const token = yield* GetAccessToken();
    let data = { status };
    if (code) {
      data = { ...data, code };
    }
    yield putProjectDetails(token, id, data);
    yield put(updateStatus.done({ id, ...data }));
  });

  yield takeLatest(updateWorkstageOrder, function* updateWorkstageOrderSaga({
    payload: { projectId, workstages },
  }) {
    const token = yield* GetAccessToken();

    yield putWorkstageOrder(token, { workstages });
    //yield put(getProject(projectId));
    yield put(updateWorkstageOrder.done());
  });

  yield takeLatest(
    updateWorkstagePercentage,
    function* updateWorkstagePercentageSaga({
      payload: { id, workstageId, percentageComplete },
    }) {
      const token = yield* GetAccessToken();
      const result = yield putWorkstagePercentageComplete(
        token,
        id,
        workstageId,
        {
          percentageComplete: percentageComplete / 100,
        }
      );

      yield put(updateWorkstagePercentage.done(result.data));
    }
  );
  yield takeLatest(favProject, function* favProjectSaga({
    payload: { projectId },
  }) {
    const token = yield* GetAccessToken();
    yield call(putFavProject, token, projectId);
    yield put(favProject.done());
    yield put(getProjects());
  });

  yield takeLatest(unfavProject, function* unfavProjectSaga({
    payload: { projectId },
  }) {
    const token = yield* GetAccessToken();
    yield call(putUnfavProject, token, projectId);
    yield put(unfavProject.done());
    yield put(getProjects());
  });
  yield takeLatest(updateProjectReview, function* updateProjectReviewSaga({
    payload: { projectId, data },
  }) {
    const token = yield* GetAccessToken();
    yield call(putProjectReview, token, projectId, data);
    yield put(updateProjectReview.done());
    yield put(getProjects());
  });
}

const projectsSelector = (state) => state.projects.projects;

export const activeProjectsSelector = createSelector(
  projectsSelector,
  (projects) =>
    projects.filter((p) => p.status === "Live" || p.status === "Enquiry")
);

export const workstagesSelector = createSelector(projectsSelector, (projects) =>
  projects.flatMap((p) => p?.budget?.workstages || [])
);
