/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import { LayoutSplashScreen } from "../../_metronic";

import * as routerHelpers from "../router/RouterHelpers";
import { init as authInit, login } from "../store/ducks/auth.duck";
import { history } from "../store/store";
import { ConnectedRouter } from "connected-react-router";

export const Routes = () => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  
  const dispatch = useDispatch();
  const {
    loading,
    isAuthenticated,
    initialized,
    menuConfig,
    userLastLocation,
  } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      loading: auth.loading,
      isAuthenticated: auth.isAuthenticated,
      initialized: auth.initialized,
      menuConfig,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!initialized && loading && !isAuthenticated) {
      dispatch(authInit());
    }
  }, [dispatch, initialized, loading, isAuthenticated]);

  useEffect(() => {
    if (!initialized) return;
    if (loading) return;
    if (isAuthenticated) {
      return;
    }
    dispatch(login(userLastLocation));
  }, [dispatch, initialized, loading, isAuthenticated, userLastLocation]);

  return (
    <ConnectedRouter history={history}>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          {loading && <LayoutSplashScreen />}
          {isAuthenticated && (
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          )}
        </Switch>
      </LayoutContextProvider>
    </ConnectedRouter>
  );
};
