export function retry(
  fn,
  fallbackFn = null,
  retriesLeft = 100,
  interval = 1000
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            if (fallbackFn) {
              fallbackFn();
              return;
            }
            reject(error);
            return;
          }

          retry(fn, fallbackFn, retriesLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
}
