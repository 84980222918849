/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import store, { persistor } from "./app/store/store";
import App from "./App";
import "./index.scss";

// import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
//import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/line-awesome/1.3.0/css/line-awesome.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-day-picker/lib/style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./_metronic/_assets/fonts/fonts.css";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn:
    "https://edb06c54940a44d7997ae0b6ed36d726@o409333.ingest.sentry.io/5281604",
});

const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById("root")
);
