import { createSagaActions } from "./utils";
import { createReducer, createAction } from "redux-act";
import { takeLatest, put } from "redux-saga/effects";
import {
  getHolidayBookingsAPI,
  getHolidayApprovalsAPI,
  getHolidayCalendarAPI,
  getHolidayAdminListAPI,
  deleteHolidayAPI,
  bookHolidayAPI,
  approveHolidayAPI,
  declineHolidayAPI,
  cancelHolidayAPI,
} from "../../crud/users.crud";
import { getMe } from "./app.duck";

import { GetAccessToken } from "./accessToken";

export const getHolidayPage = createAction("holiday/GET_HOLIDAY_PAGE");

export const getMyBookings = createSagaActions("holiday/GET_BOOKINGS");
export const getApprovals = createSagaActions("holiday/GET_APPROVALS");
export const bookHoliday = createSagaActions("holiday/BOOK_HOLIDAY");
export const approveHoliday = createSagaActions("holiday/APPROVE_HOLIDAY");
export const declineHoliday = createSagaActions("holiday/DECLINE_HOLIDAY");
export const cancelHoliday = createSagaActions("holiday/CANCEL_HOLIDAY");
export const getHolidayCalendar = createSagaActions("holiday/GET_CALENDAR");
export const getAdminHolidayList = createSagaActions("holiday/GET_ADMIN");
export const deleteHoliday = createSagaActions("holiday/DELETE");

const initialState = {
  loading: false,
  holidayCalendar: [],
  myBookings: [],
  approvals: [],
  adminList: [],
};

export const reducer = createReducer(
  {
    [getMyBookings]: (state) => ({ ...state, loading: true }),
    [getMyBookings.done]: (state, data) => ({
      ...state,
      loading: false,
      myBookings: data,
    }),
    [getMyBookings.fail]: (state) => ({ ...state, loading: false }),
    [getApprovals]: (state) => ({ ...state, loading: true }),
    [getApprovals.done]: (state, data) => ({
      ...state,
      loading: false,
      approvals: data,
    }),
    [getApprovals.fail]: (state) => ({ ...state, loading: false }),
    [getAdminHolidayList]: (state) => ({ ...state, loading: true }),
    [getAdminHolidayList.done]: (state, data) => ({
      ...state,
      loading: false,
      adminList: data,
    }),
    [getAdminHolidayList.fail]: (state) => ({ ...state, loading: false }),

    [getHolidayCalendar]: (state) => ({ ...state, loading: true }),
    [getHolidayCalendar.done]: (state, data) => ({
      ...state,
      loading: false,
      holidayCalendar: data,
    }),
    [getHolidayCalendar.fail]: (state) => ({ ...state, loading: false }),
    [bookHoliday]: (state) => ({ ...state, loading: true }),
    [bookHoliday.done]: (state, data) => ({ ...state, loading: false }),
    [bookHoliday.fail]: (state) => ({ ...state, loading: false }),
    [cancelHoliday]: (state) => ({ ...state, loading: true }),
    [cancelHoliday.done]: (state, data) => ({ ...state, loading: false }),
    [cancelHoliday.fail]: (state) => ({ ...state, loading: false }),
    [approveHoliday]: (state) => ({ ...state, loading: true }),
    [approveHoliday.done]: (state, data) => ({ ...state, loading: false }),
    [approveHoliday.fail]: (state) => ({ ...state, loading: false }),
    [declineHoliday]: (state) => ({ ...state, loading: true }),
    [declineHoliday.done]: (state, data) => ({ ...state, loading: false }),
    [declineHoliday.fail]: (state) => ({ ...state, loading: false }),
    [deleteHoliday]: (state) => ({ ...state, loading: true }),
    [deleteHoliday.done]: (state, data) => ({ ...state, loading: false }),
    [deleteHoliday.fail]: (state) => ({ ...state, loading: false }),
  },
  initialState
);

export function* saga() {
  yield takeLatest(getMyBookings, function* getMyBookingsSaga() {
    try {
      const token = yield* GetAccessToken();
      const result = yield getHolidayBookingsAPI(token);
      yield put(getMyBookings.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getMyBookings.fail());
    }
  });
  yield takeLatest(getApprovals, function* getAprrovalsSaga() {
    try {
      const token = yield* GetAccessToken();
      const result = yield getHolidayApprovalsAPI(token);
      yield put(getApprovals.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getApprovals.fail());
    }
  });
  yield takeLatest(getAdminHolidayList, function* getAdminHolidayListSaga() {
    try {
      const token = yield* GetAccessToken();
      const result = yield getHolidayAdminListAPI(token);
      yield put(getAdminHolidayList.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getAdminHolidayList.fail());
    }
  });
  yield takeLatest(getHolidayCalendar, function* getHolidayCalendarSaga() {
    try {
      const token = yield* GetAccessToken();
      const result = yield getHolidayCalendarAPI(token);
      const mapped = result.data.map((h) => ({
        ...h,
        start: new Date(h.start),
        end: new Date(h.end),
      }));
      yield put(getHolidayCalendar.done(mapped));
    } catch (error) {
      console.log(error);
      yield put(getHolidayCalendar.fail());
    }
  });
  yield takeLatest(bookHoliday, function* bookHolidaySaga({ payload: data }) {
    try {
      const token = yield* GetAccessToken();
      yield bookHolidayAPI(token, data);
      yield put(getMyBookings());
	  yield put(getApprovals());
      yield put(getHolidayCalendar());
      yield put(getMe());
	  //if I just booked holiday for someone else
	  if (data.user) {
		  yield put(getAdminHolidayList());
	  }
      yield put(bookHoliday.done());
    } catch (error) {
      console.log(error);
      yield put(bookHoliday.fail());
    }
  });
  yield takeLatest(cancelHoliday, function* cancelHolidaySaga({
    payload: { bookingId },
  }) {
    try {
      const token = yield* GetAccessToken();
      yield cancelHolidayAPI(token, bookingId);
      yield put(getMyBookings());
      yield put(getHolidayCalendar());
      yield put(getMe());

      yield put(cancelHoliday.done());
    } catch (error) {
      console.log(error);
      yield put(cancelHoliday.fail());
    }
  });
  yield takeLatest(approveHoliday, function* approveHolidaySaga({
    payload: { bookingId },
  }) {
    try {
      const token = yield* GetAccessToken();
      yield approveHolidayAPI(token, bookingId);
      yield put(getApprovals());
      yield put(approveHoliday.done());
    } catch (error) {
      console.log(error);
      yield put(approveHoliday.fail());
    }
  });
  yield takeLatest(declineHoliday, function* declineHolidaySaga({
    payload: { bookingId, note },
  }) {
    try {
      const token = yield* GetAccessToken();
      yield declineHolidayAPI(token, bookingId, note);
      yield put(getApprovals());
      yield put(declineHoliday.done());
    } catch (error) {
      console.log(error);
      yield put(declineHoliday.fail());
    }
  });
  yield takeLatest(deleteHoliday, function* deleteHolidaySaga({
    payload: { bookingId },
  }) {
    try {
      const token = yield* GetAccessToken();
      yield deleteHolidayAPI(token, bookingId);
      yield put(getAdminHolidayList());
      yield put(deleteHoliday.done());
    } catch (error) {
      console.log(error);
      yield put(deleteHoliday.fail());
    }
  });

  yield takeLatest(getHolidayPage, function* getHolidayPageSaga({
    payload: { hasHolidayAdminPermission },
  }) {
    yield put(getMe());
    yield put(getApprovals());
    yield put(getMyBookings());
    yield put(getHolidayCalendar());
    if (hasHolidayAdminPermission) {
      yield put(getAdminHolidayList());
    }
  });
}
