import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import * as timesheet from "./ducks/timesheet.duck";
import * as projects from "./ducks/projects.duck";
import * as clients from "./ducks/clients.duck";
import * as auth from "./ducks/auth.duck";
import * as app from "./ducks/app.duck";
import * as expense from "./ducks/expenses.duck";
import * as reports from "./ducks/reports.duck";
import * as holidays from "./ducks/holiday.duck";

import { metronic } from "../../_metronic";

export const createRootReducer = (history) =>
  combineReducers({
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    timesheet: timesheet.reducer,
    projects: projects.reducer,
    clients: clients.reducer,
    auth: auth.reducer,
    app: app.reducer,
    expense: expense.reducer,
    reports: reports.reducer,
    holiday: holidays.reducer,
    router: connectRouter(history),
  });

export function* rootSaga() {
  yield all([
    timesheet.saga(),
    projects.saga(),
    clients.saga(),
    expense.saga(),
    auth.saga(),
    app.saga(),
    holidays.saga(),
    reports.saga(),
  ]);
}
