import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function Footer() {
  const year = useMemo(() => new Date().getFullYear(), []);
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const googleFormLink = useMemo(
    () =>
      `https://docs.google.com/forms/d/e/1FAIpQLSe0_W-Ub0bqO5Pd91Pq6ErvuDtrKB-TXhBBr_n0aMH5EpFV9A/viewform?usp=pp_url&entry.1117965634=${window.navigator.userAgent}&entry.751926935=${user.sub}`,
    [user]
  );
  return (
    <div
      className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
      id="kt_footer"
    >
      <div className="kt-container">
        <div className="kt-footer__copyright">
          {year}&nbsp;&copy;&nbsp; Rodic Davidson
        </div>
        <div className="kt-footer__menu">
          <a
            href={googleFormLink}
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            Report an issue
          </a>
          <a
            href="https://stats.uptimerobot.com/rgpOxFAkX4"
            target="_blank"
            rel="noopener noreferrer"
            className="kt-footer__menu-link kt-link"
          >
            Status
          </a>
        </div>
      </div>
    </div>
  );
}
