import { baseURL } from "./helper";
import axios from "axios";

function options(token) {
  return {
    baseURL,
    headers: { Authorization: `Bearer ${token}` },
  };
}

export function create(token, data) {
  return axios.post(`/projects`, data, options(token));
}

export function getAll(token) {
  return axios.get(`/projects`, options(token));
}

export function getProjectDetails(token, id) {
  return axios.get(`/projects/${id}`, options(token));
}

export function putProjectDetails(token, id, data) {
  return axios.put(`/projects/${id}`, data, options(token));
}

export function putProjectNote(token, id, note) {
  const data = {
    content: note,
  };
  return axios.put(`/projects/${id}/note`, data, options(token));
}

export function putInvoiceScheduleMove(token, id, data) {
  return axios.put(`/projects/${id}/budget/invoice/move`, data, options(token));
}

export function getProjectTypes(token) {
  return axios.get("/projects/meta/types", options(token));
}

export function getPlanningAuthorities(token) {
  return axios.get("/projects/meta/authorities", options(token));
}

export function postWorkstage(token, data) {
  return axios.post(`/projects/workstage`, data, options(token));
}

export function putBudget(token, id, data) {
  return axios.put(`/projects/${id}/budget`, data, options(token));
}

export function putWorkstage(token, id, data) {
  return axios.put(`/projects/workstage/${id}`, data, options(token));
}

export function putWorkstageOrder(token, data) {
  return axios.put(`/projects/workstage/order`, data, options(token));
}

export function putFavProject(token, projectId) {
  return axios.put(`/projects/${projectId}/fav`, null, options(token));
}

export function putUnfavProject(token, projectId) {
  return axios.put(`/projects/${projectId}/unfav`, null, options(token));
}

export function putWorkstagePercentageComplete(token, id, workstageId, data) {
  return axios.put(
    `/projects/${id}/workstage/${workstageId}/percentage`,
    data,
    options(token)
  );
}

export function deleteWorkstage(token, projectId, id) {
  return axios.delete(`/projects/${projectId}/workstage/${id}`, options(token));
}

export function putProjectReview(token, projectId, data) {
  return axios.put(`/project-review/${projectId}`, data, options(token));
}

export function addProjectInvoice(token, projectId, data) {
  return axios.post(
    `/projects/${projectId}/budget/invoice`,
    data,
    options(token)
  );
}

export function removeProjectInvoice(token, projectId, invoiceId) {
  return axios.delete(
    `/projects/${projectId}/budget/invoice/${invoiceId}`,
    options(token)
  );
}
