import { takeLatest, put, call, select } from "redux-saga/effects";
import { createSagaActions } from "./utils";
import { createReducer } from "redux-act";
import { createSelector } from "reselect";

import {
  getTimesheet as getTimesheetFromService,
  getTimesheetForUser as getTimesheetForUserFromService,
  getInternalCodes as getInternalCodesFromService,
  postTimeEntry,
  moveTimeEntry as moveTimeEntryFromService,
  moveTimeEntryDay as moveTimeEntryDayFromService,
  postSubmitTimesheet,
  postUnsubmitTimesheet,
  deleteTimeEntry as deleteTimeEntryFromService,
  putTimeLogged,
} from "../../crud/time.crud";
import { GetAccessToken } from "./accessToken";

export const getTimesheet = createSagaActions("timesheet/GET_TIMESHEET");
export const removeEntry = createSagaActions("timesheet/REMOVE_ENTRY");
export const moveTimeEntry = createSagaActions("timesheet/MOVE_ENTRY");
export const moveTimeEntryDay = createSagaActions("timesheet/MOVE_ENTRY_DAY");
export const submitTimesheet = createSagaActions("timesheet/SUBMIT_TIMESHEET");
export const unsubmitTimesheet = createSagaActions(
  "timesheet/UNSUBMIT_TIMESHEET"
);

export const getInternalCodes = createSagaActions(
  "timesheet/GET_INTERNAL_CODES"
);
export const saveTimeEntry = createSagaActions("timesheet/SAVE_ENTRY");
export const updateTimeLogged = createSagaActions(
  "timesheet/UPDATE_TIMELOGGED"
);

export const initialState = {
  currentTimesheet: null,
  currentWeek: new Date(),
  loading: false,
  internalCodes: null,
};

export const reducer = createReducer(
  {
    [getTimesheet]: (state) => ({
      ...state,
      currentTimesheet: null,
      loading: true,
    }),
    [getTimesheet.done]: (state, data) => {
      return { ...state, currentTimesheet: data, loading: false };
    },
    [getTimesheet.fail]: (state) => {
      return { ...state, loading: false };
    },
    [saveTimeEntry]: (state) => ({ ...state }),
    [saveTimeEntry.done]: (state, data) => {
      return { ...state, currentTimesheet: data, loading: false };
    },
    [saveTimeEntry.fail]: (state) => {
      return { ...state, loading: false };
    },
    [updateTimeLogged]: (state) => ({ ...state }),
    [updateTimeLogged.done]: (state, data) => {
      return { ...state, currentTimesheet: data, loading: false };
    },
    [updateTimeLogged.fail]: (state) => {
      return { ...state, loading: false };
    },
    [submitTimesheet]: (state) => ({ ...state, loading: true }),
    [submitTimesheet.done]: (state, data) => {
      return { ...state, currentTimesheet: data, loading: false };
    },
    [submitTimesheet.fail]: (state) => {
      return { ...state, loading: false };
    },
    [unsubmitTimesheet]: (state) => ({ ...state, loading: true }),
    [unsubmitTimesheet.done]: (state, data) => {
      return { ...state, currentTimesheet: data, loading: false };
    },
    [unsubmitTimesheet.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getInternalCodes]: (state) => ({ ...state, loading: true }),
    [getInternalCodes.done]: (state, data) => {
      return { ...state, internalCodes: data, loading: false };
    },
    [getInternalCodes.fail]: (state) => {
      return { ...state, loading: false };
    },
    [removeEntry]: (state, { data: { project, workstage, internalCode } }) => {
      const updatedTimesheet = { ...state.currentTimesheet };
      const entryIndex = updatedTimesheet.entries.findIndex((e) => {
        if (project && workstage) {
          return (
            e.project?.id === project.id && e.workstage?.id === workstage.id
          );
        }
        return e.internalCode === internalCode;
      });
      if (entryIndex >= 0) updatedTimesheet.entries.splice(entryIndex, 1);

      return { ...state, currentTimesheet: { ...updatedTimesheet } };
    },
    [removeEntry.done]: (state, data) => ({
      ...state,
      currentTimesheet: data,
    }),

    [removeEntry.fail]: (state) => ({ ...state }),
    [moveTimeEntry]: (state) => ({ ...state, loading: true }),
    [moveTimeEntry.done]: (state, data) => ({
      ...state,
      currentTimesheet: data,
    }),
    [moveTimeEntry.fail]: (state) => ({ ...state }),

    [moveTimeEntryDay]: (state) => ({ ...state, loading: true }),
    [moveTimeEntryDay.done]: (state, data) => ({
      ...state,
      currentTimesheet: data,
    }),
    [moveTimeEntryDay.fail]: (state) => ({ ...state }),
  },
  initialState
);

export function* saga() {
  yield takeLatest(getTimesheet, function* getTimesheetSaga({
    payload: { date, impersonate },
  }) {
    const token = yield* GetAccessToken();

    const result = impersonate
      ? yield call(
          getTimesheetForUserFromService,
          token,
          new Date(date),
          impersonate
        )
      : yield call(getTimesheetFromService, token, new Date(date));
    yield put(getTimesheet.done(result.data));
  });

  yield takeLatest(getInternalCodes, function* getInternalCodesSaga() {
    const token = yield* GetAccessToken();
    const result = yield call(getInternalCodesFromService, token);
    yield put(getInternalCodes.done(result.data));
  });

  yield takeLatest(removeEntry, function* deleteTimeEntrySaga({
    payload: { data },
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(deleteTimeEntryFromService, token, data);
    yield put(removeEntry.done(result.data));
  });

  yield takeLatest(moveTimeEntry, function* moveTimeEntrySaga({
    payload: { data },
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(moveTimeEntryFromService, token, data);
    yield put(moveTimeEntry.done(result.data));
  });

  yield takeLatest(moveTimeEntryDay, function* moveTimeEntrySaga({
    payload: { data },
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(moveTimeEntryDayFromService, token, data);
    yield put(moveTimeEntryDay.done(result.data));
  });

  yield takeLatest(saveTimeEntry, function* saveTimeEntrySaga({
    payload: { data, skipUpdate },
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(postTimeEntry, token, data);
    if (!skipUpdate) yield put(saveTimeEntry.done(result.data));
  });

  yield takeLatest(updateTimeLogged, function* updateTimeLoggedSaga({
    payload: { data, skipUpdate },
  }) {
    const token = yield* GetAccessToken();
    const result = yield call(putTimeLogged, token, data);
    if (!skipUpdate) yield put(updateTimeLogged.done(result.data));
  });

  yield takeLatest(submitTimesheet, function* sumbitTimesheetSaga() {
    const token = yield* GetAccessToken();
    const timesheetId = yield select(
      (state) => state.timesheet?.currentTimesheet?.id
    );
    const result = yield call(postSubmitTimesheet, token, {
      timesheetId,
    });
    yield put(submitTimesheet.done(result.data));
  });
  yield takeLatest(unsubmitTimesheet, function* unsubmitTimesheetSaga() {
    const token = yield* GetAccessToken();
    const timesheetId = yield select(
      (state) => state.timesheet?.currentTimesheet?.id
    );
    const result = yield call(postUnsubmitTimesheet, token, {
      timesheetId,
    });
    yield put(unsubmitTimesheet.done(result.data));
  });
}

const internalCodesSelector = (state) => state.timesheet.internalCodes;

export const sortedInternalCodesSelector = createSelector(
  internalCodesSelector,
  (internalCodes) =>
    internalCodes?.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    })
);
