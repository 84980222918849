import { createAction } from "redux-act";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import { select } from "redux-saga/effects";
// Create 4 actions bound together to be used with sagas:
// - The Action (action)
// - The success action (action.done)
// - The failure action (action.fail)
// - The skip action (action.skip) - optional
export const createSagaActions = (name, payloadReducer, createSkipAction) => {
  const request = createAction(name, payloadReducer);
  request.done = createAction(name + "_DONE");
  request.fail = createAction(name + "_FAIL");

  if (createSkipAction) {
    request.skip = createAction(name + "_SKIP");
  }

  return request;
};

export const updateArrayAt = (list, index, update) => {
  if (index >= 0) {
    list = clone(list); // shallow copy of whole list
    const item = cloneDeep(list[index]); // deep copy of given item
    update(item);
    list[index] = item;
  }

  return list;
};

export function* GetUserSub() {
  const sub = yield select((state) => state.auth.user.sub);
  console.log({ sub });
  return sub.substring(6);
}
