import { createSagaActions } from "./utils";
import { createReducer } from "redux-act";
import { takeLatest, put } from "redux-saga/effects";
import { postPrintingExpenseDetails } from "../../crud/expense.crud";

import { GetAccessToken } from "./accessToken";

export const createPrinting = createSagaActions("expenses/CREATE_PRINTING");

const initialState = {
  loading: false
};

export const reducer = createReducer(
  {
    [createPrinting]: state => ({ ...state, loading: true }),
    [createPrinting.done]: state => ({ ...state, loading: false }),
    [createPrinting.fail]: state => ({ ...state, loading: false })
  },
  initialState
);

export function* saga() {
  yield takeLatest(createPrinting, function* createPrintingSaga({
    payload: { data }
  }) {
    const token = yield* GetAccessToken();
    try {
      yield postPrintingExpenseDetails(token, data);
      yield put(createPrinting.done());
    } catch (error) {
      console.log(error);
      yield put(createPrinting.fail());
    }
  });
}
