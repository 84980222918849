import { baseURL } from "./helper";
import axios from "axios";

const options = token => ({
  baseURL,
  headers: { Authorization: `Bearer ${token}` }
});

export function getAll(token) {
  return axios.get(`/clients`, options(token));
}

export function getClientDetails(token, id) {
  return axios.get(`/clients/${id}`, options(token));
}

export function postClientDetails(token, data) {
  return axios.post(`/clients`, data, options(token));
}

export function putClientDetails(token, id, data) {
  return axios.put(`/clients/${id}`, data, options(token));
}

export function deleteClientDetails(token, id) {
  return axios.delete(`/clients/${id}`, options(token));
}
