import { select, call } from "redux-saga/effects";

export function* GetAccessToken() {
  const client = yield select((state) => state.auth.auth0Client);
  try {
    return yield call({ context: client, fn: client.getTokenSilently });
  } catch (err) {
    console.error(err);
    window.location.reload();
  }
}
